/**
 * Uploads file to Cloudflare R2 bucket with progress tracking.
 * @returns http status
 */
export const uploadVideoFile = async ({
  file,
  signedUrl,
  onProgress,
}: {
  file: File;
  signedUrl: string;
  onProgress?: (progressEvent: ProgressEvent) => void; 
}) => {
  return new Promise<number>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // Track upload progress if the onProgress callback is provided
    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }

    // Handle the request completion
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.status); // Resolve the promise with the status code
      } else {
        reject(new Error(`Failed to upload file. Status: ${xhr.status}`));
      }
    };

    // Handle errors
    xhr.onerror = () => reject(new Error('Upload failed.'));

    // Open the PUT request with the signed URL
    xhr.open('PUT', signedUrl);

    // Set the Content-Type header to the file's type
    xhr.setRequestHeader('Content-Type', file.type);

    // Send the file data
    xhr.send(file);
  });
};

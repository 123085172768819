import * as Sentry from '@sentry/nextjs';
import {
  FILE_SIGN_API,
  PostRequestPayload,
  PostResponsePayload,
} from 'constants/payloads/files/sign';
import { uploadVideoFile as uploadVideoFileCloudflare } from 'services/client/cloudflare/uploadVideoFile';
import { useUploadContext } from 'context/UploadVideoContext';
import { useApiGateway } from 'hooks/useApi';
import { useRequestStatus } from 'hooks/useRequestStatus';

export const useUploadVideoFile = () => {
  const { setUploadProgress, errorUpload, setErrorUpload, uploadProgress } = useUploadContext();

  const {
    isLoading: isLoadingUpload,
    // error: errorUpload,
    setLoading: setLoadingUpload,
    // setError: setErrorUpload,
    setSuccess: setSuccessUpload,
  } = useRequestStatus();

  const {
    post: postSignFile,
    isLoading: isLoadingSignFile,
    data: dataSignFile,
    error: errorSignFile,
    resetInitialState: resetSignFile,
  } = useApiGateway<PostRequestPayload, PostResponsePayload>(FILE_SIGN_API);

  const uploadVideoFile = async ({ file }: { file: File }) => {
    if (!file) return;

    try {
      const fileName = file.name;
      const fileType = file.type;

      const fileResponse = await postSignFile({
        payload: { fileName, fileType },
      });

      const payload = fileResponse?.data;

      if (typeof payload === 'object') {
        await uploadVideoFileCloudflare({
          file,
          signedUrl: payload.signedUrl,
          onProgress: (progressEvent: ProgressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentage);
          },
        });

        resetSignFile();
        return payload;
      }

      resetSignFile();
      return;
    } catch (error) {
      Sentry.captureException(error);
      setErrorUpload('There was an error, please try again');
      throw error;
    }
  };

  return {
    uploadVideoFile,
    isLoadingSignFile,
    isLoadingUpload,
    uploadProgress,
    errorSignFile,
    errorUpload,
  };
};

import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { Controller, UseFormRegister } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import styled from 'styled-components';
import { toFormattedPhone } from 'utils/shared/phone/toFormatedPhone';
import classNames from 'styles/utils/classNames';

const preventNumberChangeOnWheel = (event: React.WheelEvent<HTMLInputElement>): void => {
  event.preventDefault();
  event.currentTarget.blur();
};

// to remove blue border on country flag
const CustomPhoneInput = styled(PhoneInput)`
  .selected-flag.open::before {
    display: none;
  }
`;

export enum InputType {
  Regular = 'regular',
  RegularWithIcon = 'regularIcon',
  Textarea = 'textarea',
  LabelInput = 'label',
  PhoneInput = 'phoneInput',
}

type Props = {
  name: string;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  placeholder: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  fieldLabel?: string;
  inputType: InputType;
  renderIcon?(): React.ReactNode;
  errorBorder?: boolean;
  isFieldLabelBold?: boolean;
  control?: any;
  setDialCode?: (val: string) => void;
};

const InputField = (props: Props) => {
  const {
    register,
    renderIcon,
    inputType,
    fieldLabel,
    name,
    inputProps,
    placeholder,
    errors,
    errorBorder = false,
    isFieldLabelBold = true,
    control,
    setDialCode,
  } = props;

  const inputNativeType = inputProps?.type;

  const buildRegister = (register: UseFormRegister<any>) => {
    return register(name, {
      valueAsNumber: inputNativeType === 'number',
    });
  };

  if (inputType === InputType.Regular) {
    return (
      <div>
        <input
          {...buildRegister(register)}
          {...(inputProps || {})}
          placeholder={placeholder}
          className="text-color-text-lightmode-placeholder::placeholder dark:text-color-text-darkmode-placeholder::placeholder hide-number-stepper h-11 w-full rounded-md border-0 bg-brand-gray-50 pl-3 pr-3 font-light text-color-text-lightmode-primary focus:outline-0 dark:text-color-text-darkmode-primary"
          onWheel={preventNumberChangeOnWheel}
        />
        <p className="bottom-1 mt-1 text-[0.75rem] text-color-error">
          {errors?.[name]?.message as any}
        </p>
      </div>
    );
  }

  if (inputType === InputType.Textarea) {
    return (
      <div>
        <textarea
          {...buildRegister(register)}
          placeholder={placeholder}
          className="text-color-text-lightmode-placeholder::placeholder dark:text-color-text-darkmode-placeholder::placeholder h-[80px] w-full rounded-md border-0 bg-brand-gray-50 pl-3 pr-3 font-light text-color-text-lightmode-primary focus:outline-0 dark:text-color-text-darkmode-primary"
        />
        <p className="bottom-1 mt-1 text-[0.75rem] text-color-error">
          {errors?.[name]?.message as any}
        </p>
      </div>
    );
  }

  if (inputType === InputType.LabelInput) {
    return (
      <div className="flex flex-col">
        <span
          className={classNames(
            'mb-2  text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary',
            isFieldLabelBold && 'font-bold',
          )}
        >
          {fieldLabel}
        </span>

        <input
          {...buildRegister(register)}
          {...(inputProps || {})}
          placeholder={placeholder}
          className="text-color-text-lightmode-placeholder::placeholder dark:text-color-text-darkmode-placeholder::placeholder hide-number-stepper h-11 w-full rounded-md border-0 bg-brand-gray-50 pl-3 pr-3 font-light text-color-text-lightmode-primary focus:outline-0 dark:text-color-text-darkmode-primary"
          onWheel={preventNumberChangeOnWheel}
        />
        <p className="bottom-1 mt-1 text-[0.75rem] text-color-error">
          {errors?.[name]?.message as any}
        </p>
      </div>
    );
  }

  if (inputType === InputType.RegularWithIcon) {
    return (
      <div className="flex flex-col">
        {fieldLabel && (
          <span className="mb-2 font-bold text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            {fieldLabel}
          </span>
        )}

        <div className="relative">
          <div className="absolute left-3 top-1/2 -translate-y-1/2 transform">
            {renderIcon && renderIcon()}
          </div>
          <input
            {...buildRegister(register)}
            {...(inputProps || {})}
            placeholder={placeholder}
            className={classNames(
              errors?.[name]?.message && errorBorder
                ? 'bg-color-bg-lightmode-error ring ring-color-bg-darkmode-icon-error dark:bg-color-bg-darkmode-error'
                : 'border-0 bg-brand-gray-50  text-color-text-lightmode-primary focus:outline-0 dark:text-color-text-darkmode-primary',
              'text-color-text-lightmode-placeholder::placeholder dark:text-color-text-darkmode-placeholder::placeholder hide-number-stepper h-11 w-full rounded-md pl-7 pr-3 font-light',
            )}
            onWheel={preventNumberChangeOnWheel}
          />
        </div>
        <p className="bottom-1 mt-1 text-[0.75rem] text-color-error">
          {errors?.[name]?.message as any}
        </p>
      </div>
    );
  }

  if (inputType === InputType.PhoneInput && control) {
    return (
      <div className="flex flex-col">
        {fieldLabel && (
          <span
            className={classNames(
              'mb-2 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary',
              isFieldLabelBold && 'font-bold',
            )}
          >
            {fieldLabel}
          </span>
        )}
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const handlePhoneNumberChange = (phone: string, country: any): void => {
              const { dialCode } = country;
              setDialCode && setDialCode(dialCode);

              let inputPhoneNumber: string = toFormattedPhone(phone);
              onChange(inputPhoneNumber);
            };

            return (
              <>
                <CustomPhoneInput
                  country={'us'}
                  value={value}
                  onChange={handlePhoneNumberChange}
                  containerClass="relative"
                  inputClass={classNames(
                    '!w-full !text-color-text-lightmode-placeholder::placeholder dark:text-color-text-darkmode-placeholder::placeholder h-11 w-full rounded-md !border-0 !bg-brand-gray-50 pl-3 pr-3 font-light !text-color-text-lightmode-primary focus:outline-0 focus:border-0 focus:!shadow-none dark:!text-color-text-darkmode-primary !focus-on-tab !md-rounded',
                    error
                      ? '!bg-color-bg-lightmode-error ring !ring-color-bg-darkmode-icon-error dark:!bg-color-bg-darkmode-error'
                      : '',
                  )}
                  placeholder={placeholder}
                />
                {error && <p className="bottom-1 mt-1 text-xs text-color-error">{error.message}</p>}
              </>
            );
          }}
        />
      </div>
    );
  }

  return null;
};

export default InputField;
